<template>
  <div id="result-header">
    <h1>{{$t('search-result.headline')}}</h1>
    <div class="result-header-sku">
      <span v-html="getPlaceHolder()"></span>: <input class="edit-search-input" v-on:keyup.enter="startSearch()" v-model="sku_edit" />
    </div>
    <div class="result-header-buttons">

      <div v-if="type" class="v-button v-primary result-header-back-button">
        <a @click="this.$router.go(-1)">
          {{$t("back")}}
        </a>
      </div>
      <div v-if="type" class="v-button v-primary result-header-back-button">
        <a @click.prevent="startSearch()">
          {{$t("search")}}
        </a>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "ResultHeader",
  props: ["sku","type"],
  data() {
    return {
      sku_edit: this.sku,
    }
  },
  methods:{
    startSearch(){
      console.log("start search");
      this.$router.push({name:'SearchResults', params:{sku:this.sku_edit, type:this.type}})
    },
    getPlaceHolder(){
      if(this.type === 'sku') {
        return this.$t('search_term');
      } else if(this.type === 'ean') {
        return "EAN"
      }
    }
  }
}
</script>
